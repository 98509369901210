import React from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <div className="form-container">
      <h2>Login</h2>
      <form>
        <label htmlFor="login-email">Email:</label>
        <input type="email" id="login-email" name="email" aria-label="Email" required />
        <label htmlFor="login-password">Password:</label>
        <input type="password" id="login-password" name="password" aria-label="Password" required />
        <button type="submit">Login</button>
      </form>
      <div className="link">
        <p>Don't have an account? <Link to="/signup">Sign up</Link></p>
      </div>
    </div>
  );
}

export default Login;
