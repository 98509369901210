import React from 'react'

import PropTypes from 'prop-types'

import './team1.css'

const Team1 = (props) => {
  return (
    <div className="team1-team1 thq-section-padding">
      <div className="team1-max-width thq-section-max-width">
        <div className="team1-section-title">
          <span className="thq-body-small team1-text">{props.content1}</span>
          <div className="team1-content">
            <h2 className="thq-heading-2 team1-text01">{props.heading1}</h2>
            <p className="thq-body-large team1-text02">{props.content2}</p>
          </div>
        </div>
        <div className="team1-content01">
          <div className="team1-content02">
            <div className="team1-row">
              <div className="team1-container">
                <div className="team1-card">
                <img className="team1-image" src={props.member1Src} alt={props.member1Alt} className="team1-image"/>
                  <div className="team1-content03">
                    <div className="team1-title"> 
                      <span className="team1-text03 thq-body-small">
                        {props.member1}
                      </span>
                      <span className="team1-text04 thq-body-small">
                        {props.member1Job}
                      </span>
                    </div>
                    <span className="team1-text05 thq-body-small">
                      {props.member1Content}
                    </span>
                  </div>
                  <div className="team1-social-icons"></div>
                </div>
                <div className="team1-card01">
                <img className="team1-image" src={props.member2Src} alt={props.member2Alt} className="team1-image"/>  
                  <div className="team1-content04">
                    <div className="team1-title01">
                      <span className="team1-text06 thq-body-small">
                        {props.member2}
                      </span>
                      <span className="team1-text07 thq-body-small">
                        {props.member2Job}
                      </span>
                    </div>
                    <span className="team1-text08 thq-body-small">
                      {props.member2Content}
                    </span>
                  </div>
                  <div className="team1-social-icons01"></div>
                </div>
              </div>
              <div className="team1-container1">
                <div className="team1-card02">
                <img className="team1-image" src={props.member3Src} alt={props.member3Alt} className="team1-image"/>  
                  <div className="team1-content05">
                    <div className="team1-title02">
                      <span className="team1-text09 thq-body-small">
                        {props.member3}
                      </span>
                      <span className="team1-text10 thq-body-small">
                        {props.member3Job}
                      </span>
                    </div>
                    <span className="team1-text11 thq-body-small">
                      {props.member3Content}
                    </span>
                  </div>
                  <div className="team1-social-icons02"></div>
                </div>
                <div className="team1-card03">
                <img className="team1-image" src={props.member4Src} alt={props.member4Alt} className="team1-image"/>  
                  <div className="team1-content06">
                    <div className="team1-title03">
                      <span className="team1-text12 thq-body-small">
                        {props.member4}
                      </span>
                      <span className="team1-text13 thq-body-small">
                        {props.member4Job}
                      </span>
                    </div>
                    <span className="team1-text14 thq-body-small">
                      {props.member4Content}
                    </span>
                  </div>
                  <div className="team1-social-icons03"></div>
                </div>
              </div>
            </div>

          </div>
          <div className="team1-content19"></div>
          <div className="team1-contesnt20"></div>
        </div>
      </div>
    </div>
  )
}

Team1.defaultProps = {
  heading1: 'Our Team',
  content1: 'Join Our Team Today!',

  heading2: 'We’re hiring!',
  content2: 'Dedication, Passion, Commitment',
  
  content3: 'We are looking for talented individuals to join our team and help us shape the future of web design. If you are passionate about technology and innovation, we want to hear from you! Check out our open positions below and apply today.',
  actionContent: 'Open positions',

  member1: 'Richard Harris',
  member1Job: 'CEO',
  member1Content:
    'Richard H is the dynamic CEO who founded the company with a mission to revolutionize website design and maintenance services. With a background in business management and a passion for technology, Richard has cultivated a culture of excellence and creativity. Under his leadership, the company has grown exponentially, earning a reputation for delivering top-notch IT solutions. In his spare time, Richard enjoys mentoring young entrepreneurs and exploring new tech innovations.',
  member1Alt: 'Image of Richard H',
  member1Src:
  '/images/richard-harris.png',

  member2: 'William Graham',
  member2Job: 'CTO',
  member2Content:
  'With over 20 years of experience in the tech industry, William Graham is a visionary leader who drives innovation at our company. As the CTO, he has spearheaded numerous successful projects, seamlessly integrating cutting-edge technologies to enhance client websites. An avid coder since his teenage years, William is also a published author of several influential tech articles and a sought-after speaker at global IT conferences.',
  member2Alt: 'Image of William Graham',
  member2Src:
  '/images/william-graham.png',
  
  member3: 'Thomas Johnson',
  member3Job: 'Infrastructure Head',
  member3Content:
  "Thomas Johnson, our Infrastructure Head, is the backbone of our technical operations. With a decade of experience in managing large-scale IT infrastructures, Thomas ensures that our clients' websites run smoothly and efficiently. His expertise in cloud computing and network security is unparalleled, and he has a knack for troubleshooting complex issues swiftly. Outside of work, Thomas is a dedicated mountain climber and a tech gadget enthusiast.",
  member3Src:
  '/images/thomas-johnson.png',
  member3Alt: 'Image of Thomas Johnson',

  member4: 'Stew Nord',
  member4Job: 'Quality Assurance Lead',
  member4Content:
    'Stew Nord, the meticulous QA Lead, is known for his keen eye for detail and commitment to quality. With over 15 years in quality assurance, Stew has developed rigorous testing protocols that guarantee our clients\' websites are flawless. His background in software development gives him a unique perspective on bug detection and prevention. Stew is also an amateur photographer, capturing stunning landscapes in his travels around the world.',
  member4Src:
  '/images/stew-nord.png',
  member4Alt: 'Image of Stew Nord',
}

Team1.propTypes = {
  member5Content: PropTypes.string,
  member8Src: PropTypes.string,
  member2Content: PropTypes.string,
  member8Content: PropTypes.string,
  heading1: PropTypes.string,
  member3Job: PropTypes.string,
  member5Src: PropTypes.string,
  member1Alt: PropTypes.string,
  member4Content: PropTypes.string,
  member3Src: PropTypes.string,
  member4Src: PropTypes.string,
  member2Job: PropTypes.string,
  content2: PropTypes.string,
  member8: PropTypes.string,
  member7: PropTypes.string,
  member6Src: PropTypes.string,
  member7Content: PropTypes.string,
  member8Job: PropTypes.string,
  heading2: PropTypes.string,
  member2: PropTypes.string,
  member6Job: PropTypes.string,
  member3Alt: PropTypes.string,
  member6Alt: PropTypes.string,
  member3Content: PropTypes.string,
  member7Src: PropTypes.string,
  member2Src: PropTypes.string,
  member2Alt: PropTypes.string,
  actionContent: PropTypes.string,
  member6: PropTypes.string,
  member5: PropTypes.string,
  member1Src: PropTypes.string,
  content3: PropTypes.string,
  member4Job: PropTypes.string,
  member5Alt: PropTypes.string,
  member1Job: PropTypes.string,
  member1: PropTypes.string,
  member4: PropTypes.string,
  member7Job: PropTypes.string,
  member4Alt: PropTypes.string,
  member3: PropTypes.string,
  member1Content: PropTypes.string,
  member7Alt: PropTypes.string,
  content1: PropTypes.string,
  member6Content: PropTypes.string,
  member8Alt: PropTypes.string,
  member5Job: PropTypes.string,
}

export default Team1
