import React from 'react';
import { Link } from 'react-router-dom';

const Signup = () => {
  return (
    <div className="form-container">
      <h2>Sign Up</h2>
      <form>
        <label htmlFor="signup-email">Email:</label>
        <input type="email" id="signup-email" name="email" aria-label="Email" required />
        <label htmlFor="signup-password">Password:</label>
        <input type="password" id="signup-password" name="password" aria-label="Password" required />
        <label htmlFor="signup-confirm-password">Confirm Password:</label>
        <input type="password" id="signup-confirm-password" name="confirm-password" aria-label="Confirm Password" required />
        <button type="submit">Sign Up</button>
      </form>
      <div className="link">
        <p>Already have an account? <Link to="/login">Login</Link></p>
      </div>
    </div>
  );
}

export default Signup;
