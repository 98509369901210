import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import './style.css';
import Home from './views/home';
import Contact from './views/contact';
import Team from './views/team';
import Login from './views/login';
import Signup from './views/signup';
import NotFound from './views/not-found';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Team} exact path="/team" />
        <Route component={Login} exact path="/login" />
        <Route component={Signup} exact path="/signup" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
